/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import RouterPrefetch from 'vue-router-prefetch'
import App from './App'
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from './router/index'

import BlackDashboard from './plugins/blackDashboard'
import i18n from './i18n'
import './registerServiceWorker'
import moment from 'moment'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import flagIcons from 'flag-icons'

import store from './store'
Vue.use(BlackDashboard)
Vue.use(VueRouter)
Vue.use(RouterPrefetch)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
})

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  flagIcons,
  render: h => h(App)
}).$mount('#app')
