
import axios from 'axios'
import authHeader from './auth.header'
require("dotenv").config();

const API_URL = process.env.VUE_APP_API_URL;//'https://api.paydone.fr/'
const tokenHeader = authHeader()

class InvoiceService {
  list () {
    const tokenHeader = authHeader();
    return axios
      .get(API_URL + 'private/invoices/', {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': tokenHeader['x-access-token']
        }
      })
      .then(response => {
        return response.data.invoices
      })
  }

  getPdfInvoiceById(invoice) {
    const tokenHeader = authHeader();
    return axios
      .get(API_URL + 'private/invoices/pdf/' + invoice._id, {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': tokenHeader['x-access-token']
        }
      })
      .then(response => {
        return response
      })
  }

  save (invoice) {
    const tokenHeader = authHeader();
    return axios
      .post(API_URL + "private/invoice/" + invoice._id, {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
        data: { invoice },
      })
      .then((response) => {
        console.log("response", response);
        return response.data.invoice;
      });
  }
}

export default new InvoiceService()
