import Vue from 'vue'
import Vuex from 'vuex'
require("dotenv").config();

import { auth } from './auth.module'
import { orders } from './orders.module'
import { customers } from './customer.module'
import { partners } from './partner.module'
import { products } from './product.module'
import { transactions } from './transaction.module'
import { users } from "./user.module";
import { invoices } from "./invoice.module";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    orders,
    customers,
    partners,
    transactions,
    products,
    users,
    invoices,
  }
})
