import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue'
// GeneralViews
import NotFound from '@/pages/NotFoundPage.vue'
//login page
import Login from '@/pages/login.vue'

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard.vue')
const Profile = () =>
  import(/* webpackChunkName: "common" */ '@/pages/Profile.vue')
const Notifications = () =>
  import(/* webpackChunkName: "common" */ '@/pages/Notifications.vue')
const Icons = () => import(/* webpackChunkName: "common" */ '@/pages/Icons.vue')
const Maps = () => import(/* webpackChunkName: "common" */ '@/pages/Maps.vue')
const Typography = () =>
  import(/* webpackChunkName: "common" */ '@/pages/Typography.vue')
const TableList = () =>
  import(/* webpackChunkName: "common" */ '@/pages/TableList.vue')
const Orders = () =>
  import(/* webpackChunkName: "common" */ '@/pages/order/OrderList.vue')
const OrderPage = () =>
  import(/* webpackChunkName: "common" */ "@/pages/order/OrderPage.vue");
const Transactions = () =>
  import(/* webpackChunkName: "common" */ '@/pages/transaction/transactionList.vue')
  const TransactionsPage = () =>
  import(/* webpackChunkName: "common" */ '@/pages/transaction/transactionPage.vue')
const Customers = () =>
  import(/* webpackChunkName: "common" */ '@/pages/customer/customerList.vue')
const Partners = () =>
  import(/* webpackChunkName: "common" */ '@/pages/partner/partnerList.vue')
const Products = () =>
  import(/* webpackChunkName: "common" */ '@/pages/product/productList.vue')

const Invoices = () =>
  import(/* webpackChunkName: "common" */ '@/pages/invoice/invoiceList.vue')

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications,
      },
      {
        path: "icons",
        name: "icons",
        component: Icons,
      },
      {
        path: "maps",
        name: "maps",
        component: Maps,
      },
      {
        path: "typography",
        name: "typography",
        component: Typography,
      },
      {
        path: "orders/:id",
        name: "ordersPage",
        component: OrderPage,
      },
      {
        path: "orders",
        name: "orders",
        component: Orders,
      },
      {
        path: "transactions/:id",
        name: "transactionsPage",
        component: TransactionsPage,
      },
      {
        path: "transactions",
        name: "transactions",
        component: Transactions,
      },
      {
        path: "customers",
        name: "customers",
        component: Customers,
      },
      {
        path: "partners",
        name: "partners",
        component: Partners,
      },
      {
        path: "products",
        name: "products",
        component: Products,
      },
      {
        path: "invoice",
        name: "invoices",
        component: Invoices,
      },
      {
        path: "resetpw",
        name: "resetpw",
        component: Login,
      },
      {
        path: "login",
        name: "login",
        component: Login,
      },
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
