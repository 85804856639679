import VueRouter from "vue-router";
import routes from "./routes";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  //if (to.query.resetpw) return next("/login")

  if (to.query && to.query.token){
    localStorage.setItem("user", JSON.stringify({
      token: to.query.token,
      language : to.query.language || 'en',
      options : {
        numberLine : to.query.numberLine || "10"
      }
    }));
  }

  if (to.query && to.query.resetpw) return next()
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  console.log("before each routes", to, from)
  document.title = "Paydone Admin Console"
  //console.log("authRequired && !loggedIn", authRequired && !loggedIn)
  if (authRequired && !loggedIn) {
    localStorage.removeItem('user')
    next('/login');
  } else {
    console.log(`trying to go from ${from.toString()} to ${to.toString()}`);
    console.log(from)
    console.log(to );
    next();
  }
});

export default router;
