import OrderService from '../services/order.service'

export const orders = {
         namespaced: true,
         state: null,
         actions: {
           list({ commit }) {
             return OrderService.list().then(
               (orders) => {
                 commit("orderListSuccess", orders);
                 return Promise.resolve(orders);
               },
               (error) => {
                 commit("orderListFailure");
                 return Promise.reject(error);
               }
             );
           },
           save({ commit }, order) {
             return OrderService.save(order).then(
               (order) => {
                 commit("orderSaveSuccess", order);
                 return Promise.resolve(order);
               },
               (error) => {
                 commit("orderSaveFailure");
                 return Promise.reject(error);
               }
             );
           },
           capture({ commit }, order) {
             return OrderService.capture(order).then(
               (order) => {
                 commit("orderCaptureSuccess", order);
                 return Promise.resolve(order);
               },
               (error) => {
                 commit("orderCaptureFailure");
                 return Promise.reject(error);
               }
             );
           },
           stats({ commit }) {
             return OrderService.stats().then(
               (stats) => {
                 commit("orderStatsSuccess", stats);
                 return Promise.resolve(stats);
               },
               (error) => {
                 commit("orderStatsFailure");
                 return Promise.reject(error);
               }
             );
           },
           get({ commit }, orderId) {
             return OrderService.get(orderId).then(
               (order) => {
                 console.log("order detail", order)
                 commit("orderGetSuccess", order);
                 return Promise.resolve(order);
               },
               (error) => {
                 commit("orderGetFailure");
                 return Promise.reject(error);
               }
             );
           },
         },
         mutations: {
           orderListSuccess(state, orders) {
             state.orders = orders;
           },
           orderListFailure(state) {
             state.orders = null;
           },
           orderSaveSuccess(state, order) {
             //state.orders = orders
           },
           orderSaveFailure(state) {
             //state.orders = null
           },
           orderGetSuccess(state, order) {
             //state.orders = orders
           },
           orderGetFailure(state) {
             //state.orders = null
           },
           orderCaptureSuccess(state, order) {
             //state.orders = orders
           },
           orderCaptureFailure(state) {
             //state.orders = null
           },
           orderStatsSuccess(state, stats) {
             state.statsOrders = stats;
           },
           orderStatsFailure(state, stats) {
             state.statsOrders = null;
           },
         },
       };
