import axios from 'axios'
import authHeader from '../services/auth.header'
require("dotenv").config();

const API_URL = process.env.VUE_APP_API_URL + "private/partners/"; //'https://api.paydone.fr/private/partners/'
const tokenHeader = authHeader()

class PartnerService {
  list () {
    const tokenHeader = authHeader();
    console.log("listing partners...");
    return axios
      .get(API_URL, {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': tokenHeader['x-access-token']
        }
      })
      .then(response => {
        console.log("we've got trans", response.data.partners)
        return response.data.partners
      })
  }

  save (partner) {
    const tokenHeader = authHeader();
    console.log("before calling axios", partner._id)
    return axios
      .post(API_URL + partner._id, {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': tokenHeader['x-access-token']
        },
        data: { partner }
      })
      .then(response => {
        console.log("response", response)
        return response.data.partners
      })
  }
  postlogo(formData) {
    const token = authHeader()
    return axios.post(API_URL + 'logo/upload', formData, { headers:{'Content-Type': 'multipart/form-data',"x-access-token" : token["x-access-token"]}  }).then((response) => {
      console.log("response", response.data)
      return response.data;
    });
  }
}

export default new PartnerService()
