import PartnerService from '../services/partner.service'

export const partners = {
  namespaced: true,
  state: null,
  actions: {
    list ({ commit }, user) {
      return PartnerService.list().then(
        partners => {
          commit('partnerListSuccess', partners)
          return Promise.resolve(partners)
        },
        error => {
          commit('partnerListFailure')
          return Promise.reject(error)
        }
      )
    },
    save ({ commit }, partner) {
      return PartnerService.save(partner).then(
        order => {
          commit('partnerSaveSuccess', partner)
          return Promise.resolve(partner)
        },
        error => {
          commit('partnerSaveFailure')
          return Promise.reject(error)
        }
      )
    },
    postlogo({ commit }, formData) {
      return PartnerService.postlogo(formData).then(
        (picResult) => {
          commit("partnerPostPicSuccess", picResult);
          return Promise.resolve(picResult);
        },
        (error) => {
          commit("partnerPostPicFailure");
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    partnerListSuccess (state, partners) {
      state.partners = partners
    },
    partnerListFailure (state) {
      state.partners = null
    },
    partnerSaveSuccess (state, partner) {
      //state.orders = orders
    },
    partnerSaveFailure (state) {
      //state.orders = null
    },
    partnerPostPicSuccess(state, pic){
      //state.partner.logo = pic.url
      //state.parnter.fullpath = pic.fullpath
    },
    partnerPostPicFailure(stat, pic){
      //state.user.avatar = null
    }
  }
}
