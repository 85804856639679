import UserService from '../services/user.service'

export const users = {
         namespaced: true,
         state: null,
         actions: {
           get({ commit }, userid) {
             return UserService.get(userid).then(
               (user) => {
                 commit("userGetSuccess", user);
                 return Promise.resolve(user);
               },
               (error) => {
                 commit("userGetFailure");
                 return Promise.reject(error);
               }
             );
           },
           save({ commit }, user) {
             return UserService.save(user).then(
               (order) => {
                 commit("userSaveSuccess", user);
                 return Promise.resolve(user);
               },
               (error) => {
                 commit("userSaveFailure");
                 return Promise.reject(error);
               }
             );
           },
           postPic({ commit }, formData) {
             return UserService.postPic(formData).then(
               (picResult) => {
                 commit("userPostPicSuccess", picResult);
                 return Promise.resolve(picResult);
               },
               (error) => {
                 commit("userPostPicFailure");
                 return Promise.reject(error);
               }
             );
           },
           updatePw({ commit }, password) {
            return UserService.updatePw(password).then(
              (response) => {
                commit("userUpdatePwSuccess", response.token);
                return Promise.resolve(response.token);
              },
              (error) => {
                commit("userUpdatePwFailure");
                return Promise.reject(error);
              }
            );
          }
         },
         mutations: {
           userGetSuccess(state, user) {
             state.user = user;
           },
           userGetFailure(state) {
             state.user = null;
           },
           userSaveSuccess(state, user) {
             //state.orders = orders
           },
           userSaveFailure(state) {
             //state.orders = null
           },
           userPostPicSuccess(state, pic){
             state.user.avatar = pic.url
             state.user.fullpath = pic.fullpath
           },
           userPostPicFailure(stat, pic){
             //state.user.avatar = null
           },
           userUpdatePwSuccess(state, user){
             state.user = user
           },
           userUpdatePwFailure(stat, pic){
            state.user = null
           }
         },
       };
