import axios from 'axios'
import authHeader from '../services/auth.header'
require("dotenv").config();

const API_URL = process.env.VUE_APP_API_URL; //'https://api.paydone.fr/'

    
class CustomerService {
  list (user) {
    const tokenHeader = authHeader();
    // console.log(authHeader());
    return axios
      .get(API_URL + "private/customers/", {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
      })
      .then((response) => {
        return response.data.customers;
      });
  }

 save (customer) {
   const tokenHeader = authHeader();
    console.log("before calling axios", customer._id)
    return axios
      .post(API_URL + "private/customers/" + customer._id, {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
        data: { customer },
      })
      .then((response) => {
        console.log("response", response);
        return response.data.customers;
      });
  }
}

export default new CustomerService()
