
import axios from 'axios'
import authHeader from '../services/auth.header'
require("dotenv").config();

const API_URL = process.env.VUE_APP_API_URL;//'https://api.paydone.fr/'
const tokenHeader = authHeader()

class TransactionService {
  list () {
    //console.log("listing transaction...", tokenHeader);
    const tokenHeader = authHeader();
    return axios
      .get(API_URL + 'private/transactions/', {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': tokenHeader['x-access-token']
        }
      })
      .then(response => {
        //console.log("we've got trans", response.data.transactions)
        return response.data.transactions
      })
  }

  save (transaction) {
    const tokenHeader = authHeader();
    console.log("before calling axios", transaction._id)
    return axios
      .post(API_URL + "public/transactions/" + transaction._id, {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
        data: { transaction },
      })
      .then((response) => {
        console.log("response", response);
        return response.data.transactions;
      });
  }

  get(transactionId) {
    const tokenHeader = authHeader();
    return axios
      .get(API_URL + `private/transactions/${transactionId}`, {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
      })
      .then((response) => {
        console.log("got from service", response.data.transaction);
        return response.data.transaction;
      });
  }
}

export default new TransactionService()
