import axios from 'axios';
import authHeader from './auth.header';
require("dotenv").config();


const API_URL =process.env.VUE_APP_API_URL +  "private/users/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
  get(userid) {
    return axios.get(API_URL, { headers: authHeader() }).then((response) => {
      console.log("we've got user", response.data.user);
      return response.data.user;
    });
  }
  postPic(formData) {
    const token = authHeader()
    console.log("Posting PIC", token)
    return axios.post(API_URL + 'files/upload', formData, { headers:{'Content-Type': 'multipart/form-data',"x-access-token" : token["x-access-token"]}  }).then((response) => {
      return response.data;
    });
  }
  updatePw(password) {
    const token = authHeader()
    console.log("Updating password", token)
    return axios.post(
                API_URL + 'resetpw', 
                password, 
                { headers:{'Content-Type': 'application/json',"x-access-token" : token["x-access-token"]}  }).then((response) => {
      return response.data;
    });
  }
  save(user) {
    return axios
      .post(API_URL, { headers: authHeader(), data: { user } })
      .then((response) => {
        console.log("we've got user", response.data.user);
        return response.data.user;
      });
  }
}

export default new UserService();