import InvoiceService from '../services/invoice.service'

export const invoices = {
  namespaced: true,
  state: null,
  actions: {
    list ({ commit }, user) {
      return InvoiceService.list().then(
        invoices => {
          commit('invoiceListSuccess', invoices)
          return Promise.resolve(invoices)
        },
        error => {
          commit('invoiceListFailure')
          return Promise.reject(error)
        }
      )
    },
    getPdfInvoiceById ({ commit }, invoice) {
      return InvoiceService.getPdfInvoiceById(invoice).then(
        doc => {
          commit('invoiceGetPdfInvoiceByIdSuccess', doc)
          return Promise.resolve(doc)
        },
        error => {
          commit('invoiceGetPdfInvoiceByIdFailure')
          return Promise.reject(error)
        }
      )
    },
    save ({ commit }, invoice) {
      return InvoiceService.save(invoice).then(
        inv => {
          commit('invoiceSaveSuccess', inv)
          return Promise.resolve(inv)
        },
        error => {
          commit('InvoiceSaveFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    invoiceListSuccess (state, invoices) {
      state.invoices = invoices
    },
    invoiceListFailure (state) {
      state.invoices = null
    },
    invoiceSaveSuccess (state, invoice) {
      //state.orders = orders
    },
    invoiceSaveFailure (state) {
      //state.orders = null
    },
    invoiceGetPdfInvoiceByIdSuccess (state, invoice) {
      //state.orders = orders
    },
    invoiceGetPdfInvoiceByIdFailure (state) {
      //state.orders = null
    }
  }
}
