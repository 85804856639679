import CustomerService from '../services/customer.service'

export const customers = {
  namespaced: true,
  state: null,
  actions: {
    list ({ commit }, user) {
      return CustomerService.list().then(
        customers => {
          commit('customerListSuccess', customers)
          return Promise.resolve(customers)
        },
        error => {
          commit('customerListFailure')
          return Promise.reject(error)
        }
      )
    },
    save ({ commit }, customer) {
      return CustomerService.save(customer).then(
        customer => {
          commit('customerSaveSuccess', customer)
          return Promise.resolve(customer)
        },
        error => {
          commit('customerSaveFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    customerListSuccess (state, customers) {
      state.customers = customers
    },
    customerListFailure (state) {
      state.customers = null
    },
    customerSaveSuccess (state, customer) {
      //state.orders = orders
    },
    customerSaveFailure (state) {
      //state.orders = null
    }
  }
}
