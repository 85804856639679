import TransactionService from '../services/transaction.service'

export const transactions = {
  namespaced: true,
  state: null,
  actions: {
    list ({ commit }, user) {
      return TransactionService.list().then(
        transactions => {
          commit('transactionListSuccess', transactions)
          return Promise.resolve(transactions)
        },
        error => {
          commit('transactionListFailure')
          return Promise.reject(error)
        }
      )
    },
    save ({ commit }, transaction) {
      return TransactionService.save(transaction).then(
        customer => {
          commit('transactionSaveSuccess', transaction)
          return Promise.resolve(transaction)
        },
        error => {
          commit('transactionSaveFailure')
          return Promise.reject(error)
        }
      )
    },
    get({ commit }, transactionId) {
      return TransactionService.get(transactionId).then(
        (tx) => {
          console.log("transaction detail", tx)
          commit("transactionGetSuccess", tx);
          return Promise.resolve(tx);
        },
        (error) => {
          commit("transactionGetFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    transactionListSuccess (state, transactions) {
      state.transactions = transactions
    },
    transactionListFailure (state) {
      state.transactions = null
    },
    transactionSaveSuccess (state, transaction) {
      //state.orders = orders
    },
    transactionSaveFailure (state) {
      //state.orders = null
    },
    transactionGetSuccess(state, transaction) {
      //state.orders = orders
    },
    transactionGetFailure(state) {
      //state.orders = null
    },
    transactionCaptureSuccess(state, transction) {
      //state.orders = orders
    },
  }
}
