import axios from 'axios'
import authHeader from '../services/auth.header'
require("dotenv").config();

const API_URL = process.env.VUE_APP_API_URL + "private/products/";
const tokenHeader = authHeader()

class ProductService {
  list () {
    console.log("listing products...");
    const tokenHeader = authHeader();
    return axios
      .get(API_URL, {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': tokenHeader['x-access-token']
        }
      })
      .then(response => {
        console.log("we've got products", response.data.products)
        return response.data.products
      })
  }

  save (product) {
    const tokenHeader = authHeader();
    console.log("before calling axios", product._id)
    return axios
      .post(API_URL + product._id, {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-access-token': tokenHeader['x-access-token']
        },
        data: { product }
      })
      .then(response => {
        console.log("response", response)
        return response.data.products
      })
  }
}

export default new ProductService()
