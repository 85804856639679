import ProductService from '../services/product.service'

export const products = {
  namespaced: true,
  state: null,
  actions: {
    list ({ commit }, user) {
      return ProductService.list().then(
        products => {
          commit('productListSuccess', products)
          return Promise.resolve(products)
        },
        error => {
          commit('productListFailure')
          return Promise.reject(error)
        }
      )
    },
    save ({ commit }, product) {
      return ProductService.save(product).then(
        product => {
          commit('productSaveSuccess', product)
          return Promise.resolve(product)
        },
        error => {
          commit('productSaveFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    productListSuccess (state, products) {
      state.products = products
    },
    productListFailure (state) {
      state.products = null
    },
    productSaveSuccess (state, product) {
      //state.orders = orders
    },
    productSaveFailure (state) {
      //state.orders = null
    }
  }
}
