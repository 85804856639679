import axios from 'axios'
import authHeader from '../services/auth.header'
require("dotenv").config();

const API_URL = process.env.VUE_APP_API_URL; //"http://api.paydone.local.co:8282/";
const tokenHeader = authHeader()

class OrderService {
  //
  list() {
    const tokenHeader = authHeader();
    console.log(authHeader());
    return axios
      .get(API_URL + "private/orders/", {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
      })
      .then((response) => {
        return response.data.orders;
      });
  }

  save(order) {
    const tokenHeader = authHeader();
    return axios
      .post(API_URL + "private/orders/" + order._id, {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
        data: { order },
      })
      .then((response) => {
        return response.data.orders;
      });
  }

  get(orderId) {
    const tokenHeader = authHeader();
    return axios
      .get(API_URL + `private/orders/${orderId}`, {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
      })
      .then((response) => {
        console.log("got from service", response.data.order);
        return response.data.order;
      });
  }

  capture(order) {
    const tokenHeader = authHeader();
    return axios
      .post(API_URL + "private/orders/" + order._id + "/capture", {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
        data: { order },
      })
      .then((response) => {
        return response.data.order;
      });
  }
  stats() {
    const tokenHeader = authHeader();
    return axios
      .get(API_URL + "private/orders/stats", {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token": tokenHeader["x-access-token"],
        },
      })
      .then((response) => {
        // console.table(response.data.stats);
        // console.table(response.data.stats, ...defaultArray)
        return response.data.stats;
      });
  }
}

export default new OrderService()
