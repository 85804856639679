import axios from 'axios'
require("dotenv").config();
import authHeader from '../services/auth.header'
const tokenHeader = authHeader()

const API_URL = process.env.VUE_APP_API_URL + 'auth/'; //'https://api.paydone.fr/auth/'

class AuthService {
  login(user) {
    console.log("process.env.VUE_APP_API_URL", process.env.VUE_APP_API_URL);
    return axios
      .post(API_URL + "login", {
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return axios.post(API_URL + "signup", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }

  resetPw(u) {
    console.log("user", u)
    return axios
    .post(API_URL + "resetpw", {
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      user: u,
    })
    .then((response) => {
      if (response.success) {
        console.log(response)
      }
      return response.data;
    });
  }
}

export default new AuthService()
