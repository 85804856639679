<template>

  <div class="login">

      <h4 slot="header">
          <img src="/img/paydone.png" alt="" style="max-width: 300px" />
        </h4>
        <h1 v-if="!resetPw" class="text-light title mt-4">
           {{ ( (resetPw || newPw) ? $t("Login.resetPw") : $t("Login.title")) }}
        </h1>
        <h1 v-if="resetPw" class="text-light title mt-4">
          {{ $t("Login.resetPw") }}
        </h1>
        <div class="row" v-if="!newPw">
        <div class="col-md-12 pl-md-1">
            <base-input
              :label="$t('user.email')"
              type="email"
              v-model="user.username"
              :placeholder="$t('user.emailplaceholder')"
              inputClass="form-control"
              name="email"
            >
            </base-input>
        </div>
      </div>
      
      <div class="row" v-if="!resetPw">
        <div class="col-md-12 pl-md-1">
          <base-input
             name="resetPw"
            :label="$t('user.password')"
            v-model="user.password"
            type="password"
            style="width: 100%"
            inputClass="form-control error-field"
            autocomplete="off"
            ref="password"
          >
          </base-input>
        </div>
      </div>
      <div class="row" v-if="newPw">
        <div class="col-md-12 pl-md-1">
          <base-input
            :label="$t('Login.passwordConfirmation')"
            v-model="passwordConfirmation"
            type="password"
            style="width: 100%"
            inputClass="form-control error-field"
            autocomplete="off"
             name="newPw"
          >
          </base-input>
        </div>
      </div>
      <div class="row" v-if="!newPw && !resetPw">
        <div class="col-md-12 pl-md-1  col-sm-12">
          <base-button
            type="primary"
            :loading="loading"
            class="btn-full mt-2 mb-2"
            size="lg"
            @click="handleLogin"
          >
            {{  $t("Login.btnLogin") }} 
          </base-button>
        </div>
      </div>
      <div class="row" v-if="resetPw">
        <div class="col-md-12 pl-md-1 col-sm-12">
          <base-button
            type="primary"
            :loading="loading"
            class="btn-full mt-2 mb-2"
            size="lg"
            @click="sendPwEmail"
          >
            {{ $t('Login.btnSendEmailPw')}}
          </base-button>
        </div>
          <div class="spacer" ></div>
        <div class="col-md-12 pl-md-1">
          <base-button
            type="secondary"
            link
            :loading="loading"
            class="btn-full mt-2 mb-2"
            size="sm"
            @click="resetPw= false"
          >
            {{  $t("Login.btnLogin") }}
          </base-button>
        </div>
      </div>
      <div class="row" v-if="newPw">
        <div class="col-md-12 pl-md-1">
          <base-button
            type="primary"
            :loading="loading"
            class="btn-full mt-2 mb-2"
            size="lg"
            @click="updatePw"
          >
            {{  $t("Login.newPw") }}
          </base-button>
        </div>
      </div>
      <div class="row" v-if="!resetPw && !newPw">
        <div class="col-md-12 pl-md-1">
          <base-button
          type="secondary"
          class="btn-full mt-2 mb-2" 
          @click="resetpw"
          link
          >
            {{ $t("Login.resetPw") }}
          </base-button>
        </div>
      </div>
  </div>
</template>
<script>
import User from "../models/user";
import Modal from "@/components/Modal";

export default {
  name: "Login",
  components: {
    Modal,
  },
  data() {
    return {
      user: new User("", ""),
      loading: false,
      form: {
        email: "",
        name: "",
        checked: [],
      },
      show: true,
      loginModalVisible: !this.$store.state.auth.status.loggedIn,
      resetPw: false,
      newPw : false,
      passwordConfirmation : "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    this.show = true;
    this.loginModalVisible = true;
    this.newPw = (this.$route.query && this.$route.query.newpw ? this.$route.query.newpw : false)
    this.resetPw = (this.$route.query && this.$route.query.resetpw ? this.$route.query.resetpw : false)
  
    if (this.loggedIn && !this.resetPw) {
      //this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.loginModalVisible = true;
    this.show = true;
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state      
      this.show = true;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    handleLogin() {
      if (this.user.username && this.user.password) {
        this.loading = true;
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.loading = false;
            this.$router.push("/dashboard");
            this.$root.$i18n.locale = this.user.language
          },
          (error) => {
            this.loading = false;
            console.log("error login", error.response)
            if (error.response && error.response.status == 401) {
              this.message= this.$t('user.nonAuthorized')
            }else{
              this.message = error.message
            }
            this.$notify({
              message: this.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
            });
          }
        );
      }
    },
    submit() {
      this.$refs.observer.validate();
    },
    clear() {
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = null;
      this.$refs.observer.reset();
    },
    setColor() {},
    resetpw() {
      console.log("reseting the password")
      this.resetPw = true
    },
    sendPwEmail() {
      // adding some validation (email format)

      // request a new password.
      try {
        const u = {email : this.user.username}
        this.loading = true;
        this.$store.dispatch("auth/resetPw", u).then(
          () => {
            this.loading = false;
            this.$notify({
              message: this.$t('Login.pwemailsent'),
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "primary",
            });
            this.resetpw = false
          },
          (error) => {
            this.loading = false;

            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            this.$notify({
              message: this.$t('Login.error'),
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
            });
          }
        );
         
      } catch (error) {
        
      }
      this.resetPw= false
      this.newPw = false
    },
    updatePw() {
      // do some verification
      console.log("sqsdd ", this.user.password )
      if (!this.user.password || this.user.password !== this.passwordConfirmation ) {
       this.$notify({
              message: this.$t('Login.passwordnotmatch'),
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
            });
        return false
      }
      this.$store.dispatch("users/updatePw", this.user).then(
        (token) => {
          this.loading = false;
          this.$notify({
            message: this.$t('Login.pwupdated'),
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push("/dashboard");
          this.resetpw = false
          this.newPw = false
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );      
    }
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  /* background-color: #f7f7f7; */
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.btn-full {
  width: 100%;
}

.loginInputClass {
  background-color: black;
}
.error-field {
  border-color: red !important;
}
.login{
  max-width: 800px;
  margin: auto;
  background-color: white;
  padding: 30px 10px 30px 30px;
  border-radius: 1%;
}
.spacer{
  margin-top: 1rem;
  border: 0;
  border-top: 1px solid rgba(1, 153, 157, 0.3);
  width: 100%;
  color: red;
}
</style>